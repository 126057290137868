<template>
    <b-sidebar
        id="sidebar"
        :visible="isSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        no-close-on-backdrop
        right
        @shown="loadData"
        @hidden="resetForm"
        @change="(val) => $emit('update:is-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0 text-capitalize">
                    {{ titleForm }}
                </h5>
                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <!-- Master Account -->
                    <validation-provider
                        #default="validationContext"
                        name="master_account"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('safr.master_account')"
                            label-for="master_account"
                            :state="getValidationState(validationContext)"
                        >
                            <v-select
                                label="user"
                                v-model="itemData.id_safr_master_account"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :placeholder="$t('safr.placeholderMasterAccount')"
                                :options="safrMasterAccounts"
                                :clearable="false"
                                :reduce="(account) => account.id"
                                input-id="master_account"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Name -->
                    <validation-provider
                        #default="validationContext"
                        name="name"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('safr.name')"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="itemData.name"
                                :state="getValidationState(validationContext)"
                                trim
                                :placeholder="$t('safr.placeholderName')"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Age -->
                    <validation-provider
                        #default="validationContext"
                        name="age"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('safr.age')"
                            label-for="age"
                        >
                            <b-form-input
                                id="age"
                                v-model="itemData.age"
                                :state="getValidationState(validationContext)"
                                trim
                                :placeholder="$t('safr.placeholderAge')"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Gender -->
                    <validation-provider
                        #default="validationContext"
                        name="gender"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('safr.gender')"
                            label-for="gender"
                            :state="getValidationState(validationContext)"
                        >
                            <v-select
                                v-model="itemData.gender"
                                :options="genders"
                                :clearable="false"
                                label="label"
                                :reduce="gender => gender.value"
                                :state="getValidationState(validationContext)"
                                :placeholder="$t('safr.placeholderGender')"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Access clerance -->
                    <validation-provider
                        #default="validationContext"
                        name="access_clearance"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('safr.accessClearance')"
                            label-for="access_clearance"
                            :state="getValidationState(validationContext)"
                        >
                            <v-select
                                v-model="itemData.id_safr_access_clearance"
                                :options="accessClearances"
                                :clearable="false"
                                label="name"
                                :reduce="accessClearance => accessClearance.id"
                                :state="getValidationState(validationContext)"
                                :placeholder="$t('safr.placeholderAccessClearance')"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Image -->
                    <validation-provider
                        v-if="!itemData.id"
                        #default="validationContext"
                        name="image"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('safr.photo')"
                            label-for="image"
                        >
                            <b-form-file
                                v-model="itemData.face"
                                accept="image/jpeg, image/png, image/jpg"
                                :placeholder="$t('safr.placeholderImage')"
                                drop-placeholder="Drop file here..."
                                :state="getValidationState(validationContext)"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Groups -->
                    <validation-provider
                        #default="validationContext"
                        name="groups"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('safr.groups')"
                            label-for="groups"
                            :state="getValidationState(validationContext)"
                        >
                            <b-row>
                                <b-col
                                    v-for="{ id,name } in groups"
                                    :key="id"
                                    cols="12"
                                    md="6"
                                >
                                    <b-form-checkbox
                                        :id="`${id}-group`"
                                        v-model="itemData.groups"
                                        name="groups"
                                        :value="name"
                                        class="mb-1 text-capitalize"
                                        switch
                                    >
                                        {{ name }}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2 text-capitalize"
                            type="submit"
                        >
                            {{ isAdd ? $t('actions.add') :$t('actions.edit') }}
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                            class="text-capitalize"
                            >
                                {{$t('actions.cancel')}}
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, ref, onMounted } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import axios from '@/libs/axios'
import useList from './useList'
import group from '@/store/group';

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        BFormCheckbox,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isSidebarActive',
        event: 'update:is-sidebar-active',
    },
    props: {
        isSidebarActive: {
            type: Boolean,
            required: true,
        },
        itemEdit: {
            type: Object,
            required: false,
        },
        isAdd: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            positive,
        }
    },
    setup(props, { emit }) {

        onMounted(async () => await lists())

        //Composables
        const { t } = useI18nUtils()

        const { contentToast } = useList()

        //Data
        const accessClearances = ref([]);

        const blankData = {
            id: null,
            id_safr_master_account: null,
            id_safr_access_clearance: null,
            name: null,
            peopleId: null,
            image_url: null,
            age: null,
            gender: null,
            face: null,
            options: null,
            groups: []
        }
        const itemData = ref(JSON.parse(JSON.stringify(blankData)))

        const resetData = () => {
            itemData.value = JSON.parse(JSON.stringify(blankData))
        }

        const genders = [
            {
                label: t('safr.genderFemale'),
                value: 'female'
            },
            {
                label: t('safr.genderMale'),
                value: 'male'
            }
        ]

        const groups = ref([]);

        const safrMasterAccounts = ref([])

        //Computed
        const titleForm = computed(() => props.isAdd ? t('safr.addPeople') : ('safr.editPeople'))

        //Methods
        const lists = async () => {
            let requestList = { isList: true }
            let apiAccessClearance = axios.get('/v1/services/facial-recognition/safr/access-clearances', { params: requestList })
            let apiGroups = axios.get('/v1/services/facial-recognition/safr/groups', { params: requestList })
            let apiMasterAccount = axios.get('/v1/services/facial-recognition/safr/master-accounts', { params: requestList })

            await Promise.all([
                apiAccessClearance,
                apiGroups,
                apiMasterAccount
            ]).then(response => {
                let [
                  responseAccessClearance,
                  responseGroups,
                  responseMasterAccounts
                ] = response

                accessClearances.value = responseAccessClearance.data.data
                groups.value = responseGroups.data.data
                safrMasterAccounts.value = responseMasterAccounts.data.data
            }).catch(() => {
                accessClearances.value = [];
                groups.value = []
                safrMasterAccounts.value = []
            })

        }

        const onSubmit = async () => {
            try {
                let formData;
                let config = {}

                if (!itemData.value.id) {
                    formData = new FormData()

                    formData.append('id_safr_master_account', itemData.value.id_safr_master_account)
                    formData.append('id_safr_access_clearance', itemData.value.id_safr_access_clearance)
                    formData.append('name', itemData.value.name)
                    formData.append('age', itemData.value.age)
                    formData.append('people_id', itemData.value.people_id)
                    formData.append('image_url', itemData.value.image_url)
                    formData.append('gender', itemData.value.gender)
                    formData.append('face', itemData.value.face)

                    itemData.value.groups.forEach(group => {
                        formData.append('groups[]', group)
                    })

                    config = {
                        headers: {
                            'Content-Type' : 'multipart/form-data'
                        }
                    }
                }

                let { data } = (itemData.value.id)
                ? await axios.put(`/v1/services/facial-recognition/safr/people/${itemData.value.id}`, itemData.value)
                : await axios.post('/v1/services/facial-recognition/safr/people', formData, config)

                let { message } = data

                contentToast(message,'success')
                emit('refetch-data')
                emit('update:is-sidebar-active', false);
            } catch (error) {
                let { message } = error.response.data

                contentToast(message,'danger')
            }
        }

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData)

        const loadData = () => {
            if (!props.isAdd) {
                itemData.value = props.itemEdit;
            }
        };

        return {
            itemData,
            onSubmit,

            accessClearances,
            refFormObserver,
            getValidationState,
            loadData,
            resetForm,
            genders,
            groups,
            safrMasterAccounts,

            titleForm
        };
    }
};
</script>
